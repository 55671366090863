<template>
  <BaseCard
    title="datos de la factura"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <SaleInvoicesProfileForm
      ref="sale-invoice-profile-form"
      v-model="saleInvoice"
    />
    <hr class="m-0 mx-2">
    <SaleInvoicesBillingForm v-model="saleInvoice" />
  </BaseCard>
</template>

<script>
import { mapActions } from 'vuex'
import ApiRestService from '@/api/base-api'
import SaleInvoicesApi from '@/api/sale-invoices-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import SaleInvoicesProfileForm from '@/components/sale-invoices/form/SaleInvoicesProfileForm.vue'
import SaleInvoicesBillingForm from '@/components/sale-invoices/form/SaleInvoicesBillingForm.vue'

export default {
  components: { BaseCard, SaleInvoicesBillingForm, SaleInvoicesProfileForm },
  data() {
    return {
      loading: false,
      saleInvoice: {},
    }
  },
  computed: {
    clientId() {
      return this.$route.query['client-id'] || null
    },
  },
  mounted() {
    this.loadClient()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async loadClient() {
      if (!this.clientId) {
        return
      }

      this.setLoading(true)
      try {
        const response = await ApiRestService.get(this.clientId, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        this.saleInvoice = { client: response.data }
      } finally {
        this.setLoading(false)
      }
    },
    async handleSaveButtonClick() {
      const validationSuccess = await this.$refs['sale-invoice-profile-form'].validateForm()
      if (!validationSuccess) {
        return
      }

      this.loading = true
      try {
        const response = await SaleInvoicesApi.create(this.saleInvoice, false)
        this.$toast('Facutra de venta creada con éxito')
        await this.$router.replace({ name: 'viewSaleInvoice', params: { id: response.data.id } })
      } catch (error) {
        this.handleError(error)
      }
      this.loading = false
    },
    handleError(error) {
      if (error.response.data?.number) {
        this.$refs['sale-invoice-profile-form'].focusNumberAndSerieError()
        this.$toast.error(error.response.data.number[0])
      } else {
        this.$toast.error('Error al crear la factura. Por favor intenteló de nuevo mas tarde.')
      }
    },
  },
}
</script>

<style scoped>
</style>
